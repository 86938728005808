import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import {
  createDynamicApiGatewayClient,
  serializeApiGatewayClient,
} from '@/config/serverSideConfig';
import { getSdk as getLessPromotionSdk } from '@/core/graphql/queries/Promotions/LessPromotions.delio.sdk.generated';
import { cn } from '@/core/ui/utils';
import { sendHomepageViewEvent } from '@/modules/analytics/eventHandlers/sendHomepageViewEvent';
import { getDelioServerSideProps } from '@/modules/auth/server/getDelioServerSideProps';
import { createCategoriesQueryVariables } from '@/modules/categories/factories/createCategoriesQueryVariables';
import { getSdk as getLessCategoriesSdk } from '@/modules/categories/queries/LessCategories.delio.sdk.generated';
import { DynamicSections } from '@/modules/home/components/DynamicSections/DynamicSections';
import { HomePageSkeleton } from '@/modules/home/components/HomePageSkeleton';
import { UvpSection } from '@/modules/home/components/UvpSection';
import { useAllHomeFeed } from '@/modules/home/hooks/useAllHomeFeed';
import { getSdk as getAllHomeFeedSdk } from '@/modules/home/queries/AllHomeFeed.delio.sdk.generated';
import { getSdk as getAnnouncementsSdk } from '@/modules/home/queries/Announcements.delio.sdk.generated';
import { RichLayout } from '@/modules/layout/components/Layouts/RichLayout';
import { useShowLocationBanner } from '@/modules/location/hooks/useShowLocationBanner';
import { getSdk as getWebPageMetadataSdk } from '@/modules/meta/queries/WebPageMetadata.delio.sdk.generated';
import { fetchCurrentStore } from '@/modules/store/server/fetchCurrentStore';

import { DelioPage } from './types';

const LocationBanner = dynamic(
  async () =>
    (await import('@/modules/home/components/LocationBanner')).LocationBanner
);

const Index: DelioPage = () => {
  const allHomeFeed = useAllHomeFeed();
  const { showLocationBanner } = useShowLocationBanner();

  const { query, replace } = useRouter();

  // We need it for the case when user has no cart and try to go to checkout
  // for example if user add something to cart and then pay on mobile application and
  // then go to checkout page on web
  useEffect(() => {
    if (query?.clearCache === 'true') {
      replace('/');
    }
  }, [query, replace]);

  useEffect(() => {
    sendHomepageViewEvent();
  }, []);

  return (
    <RichLayout>
      <div className={cn('relative', 'flex', 'flex-col', 'overflow-x-hidden')}>
        {showLocationBanner && (
          <div className={cn('empty:pb-0')}>
            <LocationBanner />
          </div>
        )}
        {allHomeFeed.isLoading ? <HomePageSkeleton /> : <DynamicSections />}
      </div>
      <UvpSection />
    </RichLayout>
  );
};

export const getServerSideProps = getDelioServerSideProps(
  ({ req, res }, { authRole }) =>
    serializeApiGatewayClient({
      client: createDynamicApiGatewayClient({
        req,
        res,
      }),
      handleRequests: async (client) => {
        const { coordinates } =
          (await fetchCurrentStore({
            client,
            authRole,
          })) || {};

        await Promise.all([
          getWebPageMetadataSdk(client).webPageMetadataQuery({
            variables: { path: '/' },
            context: { clientName: 'delio' },
          }),
          getAllHomeFeedSdk(client).allHomeFeedQuery({
            variables: { coordinates },
            context: { clientName: 'delio' },
          }),
          getLessPromotionSdk(client).lessPromotionsQuery({
            variables: { coordinates },
            context: { clientName: 'delio' },
          }),
          getLessCategoriesSdk(client).lessCategoriesQuery({
            variables: createCategoriesQueryVariables({ coordinates }),
          }),
          getAnnouncementsSdk(client).announcementsQuery({
            variables: { coordinates },
          }),
        ]);
      },
    }),
  ['home']
);

export default Index;
